import dynamic from "next/dynamic"
export const TemplatesMapping = {
  "node--bonnes_affaires":dynamic(() => import("../components/modules/bonnes_affaires/BonnesAffairesNode.jsx")),
  "node--conseils":dynamic(() => import("../components/modules/conseils/ConseilsNode.jsx")),
  "node--contacts_utiles":dynamic(() => import("../components/modules/contacts_utiles/ContactsUtilesNode.jsx")),
  "error_page":dynamic(() => import("../components/modules/error/ErrorNode.jsx")),
  "node--vactory_forum":dynamic(() => import("../components/modules/forum/nodes/forumNode.jsx")),
  "maintenance_page":dynamic(() => import("../components/modules/maintenance/MaintenanceNode.jsx")),
  "node--vactory_news":dynamic(() => import("../components/modules/news/NewsNode.jsx")),
  "node--vactory_page":dynamic(() => import("../components/modules/page/PageNode.jsx")),
}
